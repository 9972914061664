@import "../../scss/mixins";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');


.rewards {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 20px;

  &-stats {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 120px;
    align-items: flex-start;

    @include phones {
      gap: 20px;
      flex-direction: column;
      align-items: center;
    }

    &-block {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .reward {
        &-value {
          font-size: 36px;
        }

        &-label {
          font-size: 12px;
        }
      }
    }
  }

  &-form-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;

    .input {
      width: 200px;
    }

    @include phones {
      flex-direction: column;

      .input {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .loading-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

.ecosystem-rewards {

  h2 {
    color: white;
    font-size: 40px;
    margin: 10px 0 40px;
    @include phones {
      font-size: 28px;
    }
  }

  &-container {
    display: flex;
    flex-direction: row;
    gap: 80px;
    align-items: center;
    @include phones {
      flex-direction: column;
    }
  }

  &-gameception {
    img {
      width: 100%;
    }
  }


  .weighted-rewards {
    &-block {
      display: flex;
      flex-direction: column;
      background-color: rgba(#000000, 0.25);
      border: 1px solid rgba(#02FFFA, 0.15);
      border-radius: 20px;
      padding: 25px 10px 10px 10px;
      position: relative;
      gap: 10px;
      min-width: 300px;
    }

    &-caption {
      position: absolute;
      top: -18px;
      left: 0;
      right: 0;
      text-align: center;
      span {
        display: inline-block;
        background-color: #002524;
        border: 1px solid #007F7D;
        color: #02FFFA;
        border-radius: 100px;
        padding: 8px 16px;
      }
    }

    &-item {
      border: 1px solid #2C2C2C;
      border-radius: 12px;
      padding: 12px 24px;
      display: flex;
      flex-direction: row;
      font-size: 18px;

      &-caption {
        flex: 1;
        text-align: center;
      }

      background-color: #0E0E0E;
    }
  }
}
