@import "scss/variables";

* {
  box-sizing: border-box;
}

body, html {
  background-color: black;
  height: 100%;
}

p, div, span, a {
  color: white;
}

.block {
  max-width: $content-width;
  width: 100%;
  background-color: #1a1a1a80;
  border: .5px solid #2b2b2b;
  border-radius: 20px;
  -webkit-backdrop-filter: blur(50px)brightness(100%);
  backdrop-filter: blur(50px)brightness(100%);
  display: inline-block;
  padding: 10px 20px;
}

.app {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 10px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
 .spinner {
   width: 56px;
   height: 56px;
   border-radius: 50%;
   background: radial-gradient(farthest-side,#02fffa 94%,#0000) top/9px 9px no-repeat,
   conic-gradient(#0000 30%,#02fffa);
   -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 0);
   animation: spinner-c7wet2 1s infinite linear;
   display: inline-block;
 }

@keyframes spinner-c7wet2 {
  100% {
    transform: rotate(1turn);
  }
}
