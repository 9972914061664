@import "../../scss/mixins";

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @include phones {
    flex-direction: column;
    gap: 20px;

    .logo {
      width: 100%;
    }
  }

  h1 {
    color: white;
    margin: 0;
    font-size: 26px;
  }

  .logo {
    width: 200px;
    height: auto;
  }

  .wallet-panel {
    &.clickable {
      cursor: pointer;
    }

    background: #02fffa1a;
    border: 1px solid #02fffa0d;
    border-radius: 100px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 9px 10px;
    display: flex;
    color: var(--Cyan,#02fffa);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:not(.clickable) {
      @include phones {
        font-size: 11px;
      }
    }

    @include phones {
      padding: 10px 20px;
    }

    .logout-button {
      width: 10px;
      cursor: pointer;
    }
  }

}
