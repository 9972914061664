.default-button {
    color: white;
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 700;
    background-color: transparent;
    background-image: linear-gradient(180deg, #01F5F1 0%, #008E8B 100%);
    border-style: solid;
    border-width: 3px;
    border-color: #008E8B;
    border-radius: 50px;
    padding: 10px 20px;
    cursor: pointer;

    &[disabled] {
        opacity: 0.5;
    }
}

.default-button.fullwidth {
    width: 100%;
}

.default-button:hover:not([disabled]) {
    background-color: transparent;
    background-image: linear-gradient(180deg, #C242FF 0%, #B52F7F 100%);
    border-color: #8E0080;
    animation: borderPulse 1000ms infinite ease-out;
}

@keyframes borderPulse {
    0% {
        box-shadow: inset 0px 0px 0px 5px rgba(255, 255, 255,.4), 0px 0px 0px 0px rgba(255,255,255,1);
    }
    100% {
        box-shadow: inset 0px 0px 0px 3px rgba(117, 117, 255,.2), 0px 0px 0px 10px rgba(255,255,255,0);
    }
}
