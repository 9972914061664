.input {
  background: none;
  border: 1px solid;
  border-color: #acacac;
  border-radius: 50px;
  color: #d9d9d9;
  flex: 1;
  flex-grow: 1;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: -1px;
  max-width: 300px;
  padding: 10px 8px 10px 16px;
  position: relative;
  width: 300px;
}
